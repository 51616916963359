import React from "react";
import { useEffect } from "react";
import PageHeader from "../../../pageHeader/PageHeader";
import headerImage from "../../../../static/backgrounds/bg_2.webp";
import styles from "./WebTasarim.module.css";
import { Link } from "react-router-dom";
import contentImage from "../../../../static/backgrounds/web-tasarim-1.jpg";
import contentImage2 from "../../../../static/backgrounds/web-tasarim-2.jpg";
import contentImage3 from "../../../../static/backgrounds/web-tasarim-3.png";
import { Icon } from "@iconify/react";

function WebTasarim() {
  useEffect(() => {
    document.title = "Web Tasarım ve Website Fiyatları | Levent Dijital";
    document.getElementsByTagName("META")[2].name = "description";
    document.getElementsByTagName("META")[2].content =
      "Website Fiyatları. Web Tasarım. Website Kurulumu için çözümler sunan Levent Dijital, hayal ettiğiniz web sitesini sizin için anında oluşturabilir. Websiteniz ile işletmenizin görünürlüğünü artırın ve marka bilinirliğini yükseltin.";
    return () => {};
  }, []);
  let renderQuestions = () => (
    <div className={styles.container_1}>
      <div className={styles.container_question}>
        <h2>
          <strong>Website Fiyatları</strong>
        </h2>
        <p>
          İşletmenizin büyüklüğü ne olursa olsun, çevrimiçi varlığınızı
          güçlendirmek ve hedef kitlenize ulaşmak için bir web sitesine
          ihtiyacınız vardır. Ancak, web sitesi oluşturma sürecinde maliyetler
          önemli bir faktördür. Web sitesi fiyatları, birden fazla faktöre bağlı
          olarak değişkenlik gösterir. <br />
          <br /> Web sitesi fiyatları hakkında bilmeniz gereken bazı önemli
          noktalar şunlardır:
          <br />
          <br />
          <ul>
            <li>
              <strong>Web Sitesi Türü:</strong> <br /> Web sitesi fiyatları,
              ihtiyaçlarınıza ve hedeflerinize bağlı olarak farklılık gösterir.
              Basit bir kurumsal web sitesi, bir e-ticaret sitesi veya özel bir
              web uygulaması gibi farklı türlerde web siteleri bulunmaktadır.
              Her web sitesi türü farklı özelliklere, tasarımlara ve işlevlere
              sahip olduğundan, fiyatlar da buna göre değişir.
            </li>
            <li>
              <strong>Tasarım ve Özelleştirme:</strong>
              <br /> Web sitesi fiyatları, tasarımın karmaşıklığı ve
              özelleştirme düzeyi ile yakından ilişkilidir. Hazır bir şablon
              kullanmak, maliyetleri düşürebilirken, özel bir tasarım ve tam
              özelleştirme isteği fiyatı artırabilir. Web sitesi tasarımı, marka
              kimliğinizi yansıtan, kullanıcı dostu ve çekici bir şekilde
              oluşturulmalıdır.
            </li>
            <li>
              <strong>İçerik Yönetim Sistemi (CMS):</strong> <br /> Web sitesi
              fiyatları, içerik yönetim sistemi (CMS) seçimine bağlı olarak
              değişebilir. CMS, web sitesinin içeriğini güncellemek, yeni
              sayfalar eklemek ve içeriği düzenlemek için kullanılan bir
              araçtır. Popüler CMS'ler arasında WordPress, Joomla ve Drupal
              bulunur. CMS seçimi, kullanılabilirlik, özelleştirme imkanları ve
              güvenlik açısından dikkate alınmalıdır.
            </li>
            <li>
              <strong>Özellikler ve İşlevler:</strong> <br /> Web sitesi
              fiyatları, istenen özellikler ve işlevlerle doğrudan ilişkilidir.
              Özel formlar, rezervasyon sistemleri, e-ticaret entegrasyonu,
              çoklu dil desteği, blog entegrasyonu gibi özelliklerin eklenmesi
              maliyetleri artırabilir. İşletmenizin ihtiyaçlarına göre hangi
              özelliklerin önemli olduğunu belirlemek önemlidir.
            </li>
          </ul>
        </p>
      </div>
      {renderContactBar()}
      {renderContentImageBar()}

      <div className={styles.container_question}>
        <h2>
          <strong>Web Tasarım</strong>
        </h2>
        <p>
          Web tasarımı, bir web sitesinin görünümünü, kullanılabilirliğini ve
          kullanıcı deneyimini iyileştiren önemli bir unsurdur. SEO uyumlu bir
          web tasarım, web sitesinin arama motorlarında daha iyi sıralamalar
          elde etmesine yardımcı olur. İşte SEO uyumlu bir web tasarım hakkında
          bilmeniz gereken bazı önemli noktalar:
          <br />
          <br />
          <ul>
            <li>
              <strong>Kullanıcı Dostu ve İçerik Odaklı Tasarım:</strong>
              <br />
              SEO uyumlu bir web tasarım, kullanıcılar için kolay gezinme imkanı
              sunar. Kullanıcı dostu bir tasarım, ziyaretçilerin web sitesindeki
              bilgilere hızlı ve kolay erişimini sağlar. Ayrıca, web sitesinin
              anahtar kelime odaklı ve bilgilendirici içeriklerle
              zenginleştirilmesi, arama motorları tarafından daha iyi
              değerlendirilmesini sağlar.
            </li>
            <li>
              <strong>Hızlı Yükleme Süresi:</strong>
              <br />
              Web sitesinin hızlı yüklenmesi, kullanıcı deneyimini olumlu yönde
              etkiler ve arama motorlarının dikkatini çeker. Geliştirilmiş bir
              web sitesi performansı için optimize edilmiş resimler, düşük dosya
              boyutları ve önbellekleme teknikleri gibi yöntemler
              kullanılabilir.
            </li>
            <li>
              <strong>Mobil Uyumluluk: </strong>
              <br />
              Mobil cihazların kullanımının artmasıyla birlikte, web sitelerinin
              mobil uyumlu olması önem kazanmıştır. Responsive tasarım
              teknikleri kullanılarak, web sitesi farklı ekran boyutlarına ve
              cihazlara uyumlu hale getirilir. Bu, kullanıcı deneyimini artırır
              ve arama motorları tarafından tercih edilir.
            </li>
            <li>
              <strong>Doğru Başlık Etiketleri ve Meta Açıklamaları: </strong>
              <br />
              Her sayfa için uygun başlık etiketleri (title tags) ve meta
              açıklamalarının (meta descriptions) kullanılması önemlidir. Başlık
              etiketleri, sayfanın içeriğini özetler ve arama sonuçlarında
              görüntülenir. Meta açıklamaları ise arama sonuçları sayfasında
              kullanıcılara sayfa hakkında bilgi verir. Başlık etiketleri ve
              meta açıklamaları, anahtar kelimeleri doğru şekilde kullanarak
              optimize edilmelidir.
            </li>
            <li>
              <strong>URL Yapısı ve Dostu URL'ler:</strong>
              <br />
              Web sitesinin URL yapısı, arama motorlarının sayfaları daha iyi
              anlamasına yardımcı olur. URL'ler, açıklayıcı, kısa ve anahtar
              kelimeleri içerecek şekilde oluşturulmalıdır. Örneğin,
              "www.webtasarimsirketi.com/hizmetler" gibi bir URL yapısı
              kullanmak, arama motorları ve kullanıcılar tarafından daha iyi
              anlaşılır.
            </li>
            <li>
              <strong>İç Bağlantılar: </strong>
              <br />
              Web sitesi içerisindeki bağlantılar (iç bağlantılar),
              kullanıcıların farklı sayfalar arasında dolaşmasını sağlar ve
              arama motorlarının içerikleri daha iyi indekslemesini sağlar. İç
              bağlantılar, ilgili anahtar kelimeleri kullanarak optimize
              edilmeli ve kullanıcılar için doğal bir navigasyon deneyimi
              sunmalıdır.
            </li>
            <li>
              <strong>Sosyal Medya Entegrasyonu: </strong>
              <br />
              Web sitesinin sosyal medya hesaplarıyla entegre olması, içeriğin
              paylaşılabilirliğini artırır ve sosyal medya trafiği yönlendirir.
              Sosyal medya paylaşım düğmeleri ve profil bağlantıları,
              kullanıcıların web sitesini kolaylıkla paylaşmasını ve takip
              etmesini sağlar.
            </li>
            <li>
              <strong>SEO Dostu Resim Etiketleri: </strong>
              <br />
              Web sitesinde kullanılan resimlerin alt etiketleri (alt tags) ve
              dosya adları, arama motorlarına resimlerin içeriği hakkında bilgi
              verir. Anahtar kelimeleri içeren ve açıklayıcı resim etiketleri
              kullanmak, resimlerin arama sonuçlarında ve görsel aramalarda
              görünürlüğünü artırır.
            </li>
          </ul>
        </p>
      </div>
      {renderRedirectBar()}

      <div className={styles.container_question}>
        <h2>
          <strong>Biz Ne Yapıyoruz ?</strong>
        </h2>

        <p>
          <strong>Levent Dijital - Web Tasarım ve Dijital Çözümler</strong>
          <br />
          <br />
          Levent Dijital olarak, web tasarım ve dijital çözümler konusunda size
          özel hizmetler sunuyoruz. İşletmenizin çevrimiçi varlığını
          güçlendirmek, hedef kitlenizi etkilemek ve rekabette öne çıkmak için
          profesyonel bir web sitesine ihtiyaç duyduğunuzun farkındayız. Bu
          nedenle, size işletmenizi dijital dünyada büyütme konusunda destek
          olmak için buradayız.
          <br />
          <br />
          Web tasarım alanında uzman ekibimiz, modern, estetik ve kullanıcı
          dostu web siteleri oluşturmak için titizlikle çalışır. İşletmenizin
          değerlerini, hedeflerini ve marka kimliğini yansıtan özelleştirilmiş
          web tasarımlarıyla sizi diğerlerinden ayırırız. Mobil uyumlu ve SEO
          optimizasyonu yapılmış web siteleriyle, çevrimiçi görünürlüğünüzü
          artırarak potansiyel müşterilerinize kolaylıkla ulaşmanızı sağlarız.
          <br />
          <br />
          Web tasarım projelerimizde en son teknolojileri kullanırız ve
          kullanıcı deneyimini önceliğimiz olarak belirleriz. İşlevsellik, hız,
          güvenlik ve kolay kullanım gibi unsurlara önem vererek,
          ziyaretçilerinizin web sitenizde keyifli bir deneyim yaşamasını
          sağlarız. Ayrıca, içerik yönetim sistemi (CMS) entegrasyonu ile web
          sitenizi kolayca güncelleyebilir, yeni içerikler ekleyebilir ve
          işletmenizin büyümesine uyum sağlayabilirsiniz.
          <br />
          <br />
          Web tasarım hizmetlerimizin yanı sıra, dijital pazarlama stratejileri
          konusunda da size destek oluruz. Arama motoru optimizasyonu (SEO),
          sosyal medya yönetimi, içerik pazarlaması ve reklam kampanyaları gibi
          alanlarda uzman ekibimiz, işletmenizin çevrimiçi varlığını
          güçlendirmek ve hedef kitlenizi etkilemek için etkili çözümler sunar.
          <br />
          <br />
          Levent Dijital olarak, her müşterimize özel çözümler sunarak
          işletmelerin dijital başarıya ulaşmasını sağlarız. Sizlere yaratıcı,
          işlevsel ve etkileyici bir web sitesiyle birlikte dijital dünyada fark
          yaratmanız için gereken desteği sağlamaktan mutluluk duyarız.
          <br />
          <br />
          Bizimle iletişime geçin ve işletmeniz için özel bir web tasarım ve
          dijital çözüm planı oluşturalım. İhtiyaçlarınızı anlamak ve
          hedeflerinize yönelik bir strateji belirlemek için sabırsızlıkla
          bekliyoruz.
          <br /> <br />
          <Link className={styles.reach_us_link} to={"/bize-ulasin"}>
            İletişim için tıklayın.
          </Link>
          <br />
          <br />
          <Link className={styles.reach_us_link} to={"/teklif-alin"}>
            Fiyat teklifi almak için tıklayın.
          </Link>
        </p>
      </div>
    </div>
  );

  let renderContactBar = () => (
    <div className={styles.contactBar_container}>
      <ul>
        <li>
          <a href="#footer">İletişim</a>
        </li>
        <li>
          <Link to="/teklif-alin">Fiyat Alın</Link>
        </li>
      </ul>
    </div>
  );

  let renderRedirectBar = () => (
    <div className={styles.contactBar_container}>
      <ul>
        <li>
          <Link to="/dijital-reklamcilik/reklam-vermek">Google Reklamları</Link>
        </li>
        <li>
          <Link to="/dijital-reklamcilik/internet-reklami">
            İnternet Reklamları
          </Link>
        </li>
      </ul>
    </div>
  );

  let renderContentImageBar = () => (
    <div className={styles.content_image_container}>
      <img
        className={styles.contentImage}
        src={contentImage}
        alt="Web Tasarım "
      />
      <img
        className={styles.contentImage}
        src={contentImage2}
        alt="Website Fiyatları"
      />
      <img
        className={styles.contentImage}
        src={contentImage3}
        alt="E-Ticaret Sitesi"
      />
    </div>
  );

  let renderCampaignBar = () => (
    <div className={`${styles.campaign_container} ${styles.container_1}`}>
      <h2>
        <strong>Yaz'a Özel İndirim</strong>
      </h2>
      <p>
          <li>
            <strong>2.500,00₺</strong>’den başlayan fiyatlarla kurumsal web sitesi sahibi
            olun.
          </li>
          <li>
            Kurumsal kimliğinize uygun <strong>özelleştirilmiş</strong> web tasarım.
          </li>
          <li>
            <strong>Yönetim paneli</strong> ile kolayca ürün ve hizmetlerinizin yönetimini sağlayın.
          </li>
          <li>
            <strong>E-Ticaret & Kurumsal</strong> web siteleri için <strong>SEO</strong> standartlarına uygun kodlama.
          </li>
          <li>
            <strong>1 Yıl</strong> boyunca ücretsiz bakım ve destek hizmeti
            alın.
          </li>
          <li>
            Kampanya '<strong>31.08.2023</strong>' tarihine kadar geçerlidir.
          </li>
          

      </p>
    </div>
  );

  return (
    <div className={styles.container}>
      <PageHeader
        title="Web Tasarım ve Website Fiyatları"
        image={headerImage}
      />

      {renderCampaignBar()}
      {renderContactBar()}
      {renderQuestions()}
    </div>
  );
}

export default WebTasarim;
