import React from "react";
import styles from "./BizeUlasin.module.css";
import { useEffect } from "react";
import PageHeader from "../../../pageHeader/PageHeader";
import headerImage from "../../../../static/backgrounds/bg_4.webp";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

function BizeUlasin() {
  useEffect(() => {
    document.title = "Bize Ulaşın | Levent Dijital";
    return () => {};
  }, []);

  return (
    <Link
    rel="noreferrer"
    to={"/teklif-alin"}
    className={styles.contact_link}
  >
    <li>
      <div className={styles.contact_content_area}>
        <p>Teklif Alın</p>
      </div>
      <Icon
        fontSize={18}
        color="white"
        className={styles.forwardIcon}
        icon="akar-icons:arrow-forward"
      />
    </li>
  </Link>
  );
}

export default BizeUlasin;
