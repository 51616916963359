import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import styles from "./ContactBar.module.css";
import { Link } from "react-router-dom";

function ContactBar() {
  return (
    <Link rel="noreferrer" to={"/teklif-alin"} className={styles.contact_link}>
      <li>
        <div className={styles.contact_content_area}>
          <p>Teklif Alın</p>
        </div>
        <Icon
          fontSize={18}
          color="white"
          className={styles.forwardIcon}
          icon="akar-icons:arrow-forward"
        />
      </li>
    </Link>
  );
}

export default ContactBar;
