import React from "react";
import { useEffect } from "react";
import PageHeader from "../../../../pageHeader/PageHeader";
import headerImage from "../../../../../static/backgrounds/bg_10.webp";
import styles from "./LogoTasarim.module.css";
// import { Link } from "react-router-dom";

// import photo1 from "../../../../../static/logo-tasarim/1.jpg";
// import photo2 from "../../../../../static/logo-tasarim/2.jpg";
// import photo3 from "../../../../../static/logo-tasarim/3.jpg";
// import photo4 from "../../../../../static/logo-tasarim/4.jpg";
// import photo5 from "../../../../../static/logo-tasarim/5.jpg";
import photo6 from "../../../../../static/logo-tasarim/6.webp";
import photo7 from "../../../../../static/logo-tasarim/7.webp";
import photo8 from "../../../../../static/logo-tasarim/8.webp";
import photo9 from "../../../../../static/logo-tasarim/9.webp";
import photo10 from "../../../../../static/logo-tasarim/10.webp";
import photo11 from "../../../../../static/logo-tasarim/11.webp";
import photo12 from "../../../../../static/logo-tasarim/12.jpeg";
import photo13 from "../../../../../static/logo-tasarim/13.jpeg";
import photo14 from "../../../../../static/logo-tasarim/14.jpeg";
import photo15 from "../../../../../static/logo-tasarim/15.jpeg";
import photo16 from "../../../../../static/logo-tasarim/16.jpeg";
import photo17 from "../../../../../static/logo-tasarim/17.jpeg";

import { Icon } from "@iconify/react";

function LogoTasarim() {
  useEffect(() => {
    document.title = "Logo Tasarım | Levent Dijital";
    return () => {};
  }, []);

  let renderQuestions = () => (
    <div className={styles.container_1}>
      <div className={styles.container_question}>
        <h2>
          <strong>Logo Tasarımı</strong>
        </h2>
        <p>
          Logo tasarımınız, markanızın dijital ortamda karakterini sergiler.
          <br />
          <br />
          Müşterileriniz sizi logonuz ile tanır. Logonuz markanızın karakterini
          temsil etmeli, tasarım çizgileriniz benzersiz bir karakter
          oluşturmalıdır.
          <br />
          <br /> Markanızı pazarlarken, marka karakterinizi korumanız ve
          müşterilerinize gerçekçi bir dijital deneyim sunmanız gerekir.
          Tasarımınızın marka karakterinize özgün ve gerçekçi olması, markanıza
          gereken özgüveni sağlayacaktır.
          <br />
          <br />
          Tasarım çizgileri, markanızın karakterini oluşturur. Müşterileriniz
          ile daha etkili etkileşim kurmanızı sağlar.
          <br />
          <br />
          Levent Dijital olarak, logo ve marka tasarım ihtiyacınız için
          benzersiz bir deneyim sunuyoruz.
        </p>
      </div>
    </div>
  );

  let renderContactBar = () => (
    <div className={styles.contactBar_container}>
      <ul>
        <li>
          <a href="#footer">İletişim</a>
        </li>
        <li>
          <a href="#paketler">Paketler ve Fiyatlar</a>
        </li>
      </ul>
    </div>
  );

  let renderOurWorks = () => (
    <div className={styles.ourWorks_container}>
      <h2 className={styles.ourWorks_title}>
        <strong>Logo Tasarım Çalışmalarımız</strong>
      </h2>
      <ul className={styles.ourWorks}>
        <li className={styles.ourWork}>
          <img src={photo6} alt="Araç alım satım firması" />
          <p>
            <strong>Araç Al-Sat Firması</strong>
          </p>
        </li>
        <li className={styles.ourWork}>
          <img src={photo8} alt="Otomotiv firması" />
          <p>
            <strong>Otomotiv Firması</strong>
          </p>
        </li>
        <li className={styles.ourWork}>
          <img src={photo14} alt="Mobilya firması" />
          <p>
            <strong>İnşaat Firması</strong>
          </p>
        </li>
        <li className={styles.ourWork}>
          <img src={photo10} alt="Madencilik firması" />
          <p>
            <strong>Madencilik Firması</strong>
          </p>
        </li>
        <li className={styles.ourWork}>
          <img src={photo13} alt="Madencilik firması" />
          <p>
            <strong>İnşaat Firması</strong>
          </p>
        </li>
        <li className={styles.ourWork}>
          <img src={photo15} alt="Madencilik firması" />
          <p>
            <strong>Mutfak Eşyaları Firması</strong>
          </p>
        </li>
        <li className={styles.ourWork}>
          <img src={photo16} alt="Madencilik firması" />
          <p>
            <strong>Mutfak Eşyaları Firması</strong>
          </p>
        </li>
        <li className={styles.ourWork}>
          <img src={photo17} alt="Madencilik firması" />
          <p>
            <strong>Mobilya Firması</strong>
          </p>
        </li>
      </ul>
    </div>
  );

  let renderPricePackages = () => (
    <div id="paketler" className={styles.packages_container}>
      <h1 className={styles.packages_title}>
        <strong>Paketler ve Fiyatlar</strong>
      </h1>
      <ul className={styles.packages}>
        <li className={styles.package}>
          <h3 className={styles.package_title}>Başlangıç</h3>
          <div className={styles.package_content}>
            <ul>
              <li>3 Günde Teslimat</li>
              <li>1 Revizyon Hakkı</li>
              <li>1 Farklı Tasarım</li>
              <li>Kaynak Dosyalar</li>
              <li>Yüksek Çözünürlük</li>
              <li>3D Mockup</li>
              <li>Sosyal Medya Kiti</li>
              <li>Kurumsal Kimlik</li>
              <li>Vektör Dosyası</li>
            </ul>
          </div>
          <div className={styles.package_price}>
            <p>750,00 ₺</p>
          </div>
        </li>
        <li className={styles.package}>
          <h3 className={styles.package_title}>Temel</h3>
          <div className={styles.package_content}>
            <ul>
              <li>2 Günde Teslimat</li>
              <li>2 Revizyon Hakkı</li>
              <li>1 Farklı Tasarım</li>
              <li>Kaynak Dosyalar</li>
              <li>Yüksek Çözünürlük</li>
              <li>3D Mockup</li>
              <li>Sosyal Medya Kiti</li>
              <li>Kurumsal Kimlik</li>
              <li>Vektör Dosyası</li>
            </ul>
          </div>
          <div className={styles.package_price}>
            <p>1.000,00 ₺</p>
          </div>
        </li>
        <li className={styles.package}>
          <h3 className={styles.package_title}>Kurumsal</h3>
          <div className={styles.package_content}>
            <ul>
              <li>2 Günde Teslimat</li>
              <li>3 Revizyon Hakkı</li>
              <li>2 Farklı Tasarım</li>
              <li>Kaynak Dosyalar</li>
              <li>Yüksek Çözünürlük</li>
              <li>3D Mockup</li>
              <li>Sosyal Medya Kiti</li>
              <li>Kurumsal Kimlik</li>
              <li>Vektör Dosyası</li>
            </ul>
          </div>
          <div className={styles.package_price}>
            <p>1.500,00 ₺</p>
          </div>
        </li>
      </ul>
    </div>
  );

  let renderCampaignBar = () => (
    <div className={`${styles.campaign_container} ${styles.container_1}`}>
      <h2>
        <strong>Yaz'a Özel İndirim</strong>
      </h2>
      <p>
          <li>
            <strong>499,00₺</strong>’den başlayan fiyatlarla logo sahibi
            olun.
          </li>
          <li>
            Kampanya '<strong>31.08.2023</strong>' tarihine kadar geçerlidir.
          </li>
      </p>
    </div>
  );


  return (
    <div className={styles.container}>
      <PageHeader title="Logo Tasarım" image={headerImage} />

      {renderCampaignBar()}
      {renderContactBar()}
      {renderQuestions()}
      {renderOurWorks()}
      {renderPricePackages()}
    </div>
  );
}

export default LogoTasarim;
